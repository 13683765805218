import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="py-4 qp-bg-green text-white">
            <Container className="pt-3">
                <Row>
                    {/* About QuickPurse */}
                    <Col md={4} className="">
                        <h5>About QuickPurse</h5>
                        <p>
                            QuickPurse is a trusted platform for seamless
                            payments, secure transactions, and convenient
                            financial management.
                        </p>
                    </Col>

                    {/* Quick Links */}
                    <Col md={4} className="">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li>
                                <Link
                                    to={"/"}
                                    className="text-decoration-none text-white"
                                >
                                    Home
                                </Link>
                            </li>
                            {/* <li>
                                <a
                                    href="/about"
                                    className="text-decoration-none text-white"
                                >
                                    About Us
                                </a>
                            </li> */}
                            <li>
                                <Link
                                    to={"/contact_us"}
                                    className="text-decoration-none text-white"
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={"/faqs"}
                                    className="text-decoration-none text-white"
                                >
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </Col>

                    {/* Contact Info */}
                    <Col md={4} className="">
                        <h5>Contact Us</h5>
                        <div>
                            Email:{" "}
                            <a
                                href="mailto:support@quickpurse.com"
                                className="text-decoration-none text-white"
                            >
                                support@quickpurse.com
                            </a>
                        </div>
                        <div>Phone: +2347088822373</div>
                        <div>
                            Address: Office 7, Block B, Rauf Aregbesola Complex,
                            Ipaja, Lagos.
                        </div>
                        {/* <div className="d-flex mt-3">
                            <a
                                href="https://facebook.com/quickpurse"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                                style={{ color: "#28a745" }}
                                aria-label="Facebook"
                            >
                                <i className="fab fa-facebook fa-lg"></i>
                            </a>
                            <a
                                href="https://twitter.com/quickpurse"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                                style={{ color: "#28a745" }}
                                aria-label="Twitter"
                            >
                                <i className="fab fa-twitter fa-lg"></i>
                            </a>
                            <a
                                href="https://instagram.com/quickpurse"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#28a745" }}
                                aria-label="Instagram"
                            >
                                <i className="fab fa-instagram fa-lg"></i>
                            </a>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
